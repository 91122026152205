import {
  FeaturesContent as baseFeaturesText,
  HomeContent as baseHomePageText,
  getLocalizedContent,
} from "@onzeit/shared";

import { useParams } from "@remix-run/react";
import { Homepage } from "~/components/frontend";

export default function Index() {
  const { locale } = useParams();

  const homepageText = getLocalizedContent(locale!, baseHomePageText);
  const featuresText = getLocalizedContent(locale!, baseFeaturesText);

  return <Homepage featuresText={featuresText} homepageText={homepageText} />;
}
